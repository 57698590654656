<template>
  <invoice-data :auction-id="auctionId" url="auction-invoice"></invoice-data>
</template>

<script>
import InvoiceData from "@/components/Invoices/InvoiceData";

export default {
  components: { InvoiceData },
  computed: {
    auctionId() {
      return this.$route.params.auction;
    },
  },
};
</script>
